import axios from "axios";
import { useRef, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import { BsBoxSeam } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { AnimatableNumericValue } from "react-native";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getSessionData } from "../../../../utils/storageUtils";
import { showConfirmationDialog } from "../../../../components/Formularios/Modal/ModalExcluir";

//Interface para receber as informações por parametros
interface ModalPickingVarOPProps {
    isOpen: boolean;
    fecharModal: () => void;
    pedido: any;
    codigo: any;
    descricao: any;
    qtdePedido: any;
    minValidade: any;
    CNPJ: any;
    WMS: any;
    lote: any;
    dataCarga: any;
    pedidoCliente: any;
    token: any;
    idFilial: any;
}

const ModalPickingVarOP: React.FC<ModalPickingVarOPProps> = ({
    isOpen,
    fecharModal,
    pedido,
    codigo,
    descricao,
    qtdePedido,
    minValidade,
    CNPJ,
    WMS,
    lote,
    dataCarga,
    pedidoCliente,
    token,
    idFilial
}) => {
    const [validadeMinima, setValidadeMinima] = useState("")
    const [barra, setBarra] = useState("")
    const [peso, setPeso] = useState(0)
    const [codUA, setCodUA] = useState<any>()
    const [UA, setUA] = useState<any>()
    const [validadeUA, setValidadeUA] = useState<any>()
    const [viewValidade, setViewValidade] = useState(true)
    const [viewLote, setViewLote] = useState(true)
    const [qtdeSeparado, setQtdeSeparado] = useState(0)

    const usuarioLogado = getSessionData("NomeUsuario")

    const barraRef = useRef(null)
    const btnSepararRef = useRef(null)

    const navigate = useNavigate()

    function handleModalOpen() {
        if (!lote) {
            setViewLote(true)
            setViewValidade(false)
            if (!minValidade) {
                // let hoje = new Date();
                // let dia = String(hoje.getDate()).padStart(2, '0');
                // let mes = String(hoje.getMonth() + 1).padStart(2, '0');
                // let ano = hoje.getFullYear();
                // setValidade(`${dia}/${mes}/${ano}`)
                setValidadeMinima(dataCarga)
            } else {
                setValidadeMinima(minValidade)
            }
        } else {
            setViewLote(false)
            setViewValidade(true)
        }

        setBarra("")
        setPeso(0)
        setCodUA("")
        setValidadeUA("")
        setUA("")
    }

    function consultaBarra(ref: any) {
        if (!barra) {
            toast.info("Insira um número válido para barra")
            setPeso(0)
            setCodUA("")
            setValidadeUA("")
            setUA("")
            return;
            // ref.current?.focus()
        }

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/picking/consulta/${barra}/${CNPJ}/${WMS}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setPeso(resposta.data.peso)
            setCodUA(resposta.data.codProd)
            setValidadeUA(resposta.data.validade)
            setUA(resposta.data.UA)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.info(erro.response.data)
                setPeso(0)
                setCodUA("")
                setValidadeUA("")
                setUA("")
                ref.current?.focus()
            }
        })
    }


    function separarVarOP(ref: any) {
        if (!barra) {
            toast.info("Insira um número válido para barra")
            setPeso(0)
            setCodUA("")
            setValidadeUA("")
            setUA("")
            // ref.current?.focus()
        }

        axios.post(`${process.env.REACT_APP_API_BASE_URL}/separar/variadoOP?idFilial=${idFilial}`, {
            barra,
            WMS,
            codUA,
            UA,
            validadeUA,
            lote,
            validadeMinima,
            pedido,
            dataCarga,
            CNPJ,
            usuarioLogado,
            pedidoCliente,
            qtdePedido
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            consultaQtdeSeparado()
            toast.success(resposta.data.message)
            if (resposta.data.telaParaAbrir == "seleciona codigo") {
                fecharModal()
            } else if (resposta.data.telaParaAbrir == "seleciona depositante") {
                navigate("/menu/picking")
            } else {
                handleModalOpen()
                ref.current.focus()
            }
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 406) {
                toast.info(erro.response.data)
                ref.current.focus()
            }
        })
    }

    const handleKeyDown = (event: any, ref: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            ref.current?.focus();
        }
    };


    async function excluirBarra() {
        await showConfirmationDialog(
            "Excluir Barras",
            `Tem certeza que deseja excluir barras\n?`,
            "question"
        ).then(function (resposta) {
            if (resposta.confirmed) {

                //Fazendo um split para desmonstar a data e remontá-la no formato yyyy/mm/dd
                let [day, month, year] = dataCarga.split('/');
                let formattedDate = `${year}-${month}-${day}`;

                axios.delete(`${process.env.REACT_APP_API_BASE_URL}/excluir/barra/${pedido}/${formattedDate}/${CNPJ}/${WMS}?idFilial=${idFilial}`, {
                    headers: {
                        Authorization: token
                    }
                }).then(function (resposta) {
                    toast.success(resposta.data)
                    handleModalOpen()
                    consultaQtdeSeparado()
                    // consultaEquipamento()
                }).catch(function (erro) {
                    toast.error("Um erro inesperado aconteceu ao tentar excluir barras")
                })
            }
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(
                    `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
                );
            }
        })
    }

    function consultaQtdeSeparado() {
        //Fazendo um split para desmonstar a data e remontá-la no formato yyyy/mm/dd
        let [day, month, year] = dataCarga.split('/');
        let formattedDate = `${year}-${month}-${day}`;

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/${pedido}/${CNPJ}/${WMS}/${formattedDate}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setQtdeSeparado(resposta.data.qtdSeparado)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(
                    `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
                );
            }
        })
    }
    return (
        <>
            <Modal className="mt-3" show={isOpen} onShow={() => {
                handleModalOpen()
                consultaQtdeSeparado()
            }}>
                <Modal.Header className="bg-white" style={{ justifyContent: 'center' }}>
                    <Modal.Title style={{ flex: '1', textAlign: 'center' }}>{"Picking - Variado Óptico"}</Modal.Title>
                    <i
                        className="bi bi-x-circle"
                        style={{ fontSize: '2rem' }}
                        onClick={() => {
                            fecharModal()
                        }}
                    ></i>
                </Modal.Header>
                <Modal.Body className="bg-white">
                    <Row className="mt-2">
                        <div className="col form-floating">
                            <input
                                // type="text"
                                value={barra}
                                className="form-control bordasInferiorInput text-black"
                                autoFocus
                                ref={barraRef}
                                onChange={(event) => {
                                    setBarra(event.target.value)
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        handleKeyDown(event, btnSepararRef)
                                        consultaBarra(barraRef)
                                    }
                                }}
                                onBlur={() => consultaBarra(barraRef)}
                            />
                            <label className="form-label">INFORME A BARRA</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-7 form-floating">
                            <input
                                // type="text"
                                value={codUA}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">CÓDIGO</label>
                        </div>

                        <div className="col-5 form-floating">
                            <input
                                // type="text"
                                value={peso}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                                style={{ textAlign: 'right' }}
                            />
                            <label className="form-label">PESO</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-7 form-floating">
                            <input
                                type="text"
                                value={validadeUA}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">VALIDADE</label>
                        </div>

                        <div className="col-5 form-floating">
                            <input
                                // type="text"
                                value={UA}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                                style={{ textAlign: 'right' }}
                            />
                            <label className="form-label">U.A.</label>
                        </div>
                    </Row>


                    <Row className="mt-3 d-flex mb-2 justify-content-between">
                        <div className="col-6">
                            <button
                                type="button"
                                className="w-100 me-2 btn btn-danger rounded fs-5"
                                onClick={excluirBarra}
                            >
                                <span className='me-1'>Excluir Barras</span><MdDeleteOutline size={20} />
                            </button>
                        </div>
                        <div className="col-6">
                            <button
                                type="button"
                                className="w-100 btn btn-novo rounded fs-5"
                                ref={btnSepararRef}
                                onClick={() => separarVarOP(barraRef)}
                            >
                                <span className='me-1'>Separar</span><BsBoxSeam size={20} />
                            </button>
                        </div>
                    </Row>

                    <Row className="mt-2 border border-primary"></Row>

                    <Row>
                        <div className="col-7 form-floating mt-1">
                            <input
                                // type="text"
                                value={pedido}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">PEDIDO</label>
                        </div>

                        <div className="col-5 form-floating mt-1">
                            <input
                                // type="text"
                                value={codigo}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                                style={{ textAlign: 'right' }}
                            />
                            <label className="form-label">CÓDIGO</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-7 form-floating mt-1">
                            <input
                                // type="text"
                                value={descricao}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">DESCRIÇÃO</label>
                        </div>

                        <div className="col-5 form-floating mt-1">
                            <input
                                // type="text"
                                value={qtdePedido}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                                style={{ textAlign: 'right' }}
                            />
                            <label className="form-label">QTDE. PEDIDO</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-7 form-floating mt-1" hidden={viewValidade}>
                            <input
                                // type="text"
                                value={validadeMinima}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">MIN. VALIDADE</label>
                        </div>

                        <div className="col-7 form-floating" hidden={viewLote}>
                            <input
                                type="text"
                                // value={descricao}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">LOTE ACEITO</label>
                        </div>

                        <div className="col form-floating mt-1">
                            <input
                                // type="text"
                                value={qtdeSeparado}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                                style={{ textAlign: 'right' }}
                            />
                            <label className="form-label">QTDE. SEPARADO</label>
                        </div>
                    </Row>

                </Modal.Body>

                <Modal.Footer className="bg-white">

                    {/* <Row className="d-flex mb-2 justify-content-between w-100">
                        <div className="col-6">
                            <button
                                type="button"
                                className="w-100 me-2 btn btn-danger rounded fs-5"
                                onClick={excluirBarra}
                            >
                                <span className='me-1'>Excluir Barras</span><MdDeleteOutline size={20} />
                            </button>
                        </div>
                        <div className="col-6">
                            <button
                                type="button"
                                className="w-100 btn btn-novo rounded fs-5"
                                ref={btnSepararRef}
                                onClick={() => separarVarOP(barraRef)}
                            >
                                <span className='me-1'>Separar</span><BsBoxSeam size={20} />
                            </button>
                        </div>
                    </Row> */}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalPickingVarOP
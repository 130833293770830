function convertData(data: string) {
    try {

        let [dia, mes, ano] = data.split('/')
        let newData = `${ano}-${mes}-${dia}`

        return newData
        
    } catch (error) {
        throw new Error("Ocorreu um erro ao tentar converter data")
    }
}

export default convertData
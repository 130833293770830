
import Modal from 'react-bootstrap/Modal';

interface ModalConfirmacao {
    icone?: string;
    mostrar: boolean;
    msgAcao1: string;
    msgAcao2: string;
    msgPrincipal: string;
    Acao1: () => void;
    Acao2: () => void;
}

function ModalConfirmacao({
    icone = "bi bi-question-lg",
    mostrar = false,
    msgAcao1,
    msgAcao2,
    msgPrincipal,
    Acao1,
    Acao2
}: ModalConfirmacao) {

    return (
        <Modal  show={mostrar} centered>
            <Modal.Body className='bg-white'>
                <div className='conatiner'>
                    <div className='row'>
                        <div className='col-lg col-md col-sm text-center'>
                            <i className={`${icone} fs-1`}></i>
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm'>
                            <h4 className='text-center'>{msgPrincipal}</h4>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='bg-white'>
                <div className='row w-100 justify-content-between'>
                    <div className='col-6'>
                        <button onClick={Acao1} className='btn btn-danger w-100 me-2'>{msgAcao1}</button>
                    </div>
                    <div className='col-6'>
                        <button onClick={Acao2} className='btn btn-novo w-100'>{msgAcao2}</button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalConfirmacao